<template>
    <div>
        <card-header title="Duplicate Trip" icon="fa-copy"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-align-left" title="New Trip Information"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Title" label-position="inside">
                                <b-input v-model="trip.title"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Location" label-position="inside">
                                <b-input v-model="trip.location"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Start Date" label-position="inside">
                                <b-input type="date" v-model="trip.start_on"/>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="End Date" label-position="inside">
                                <b-input type="date" v-model="trip.end_on"/>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-align-left" title="Duplication Options"/>

                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="trip.days">Days</b-switch>
                        </div>
                        <div class="column">
                            <b-switch v-model="trip.events">Events</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="trip.addons">Add-Ons</b-switch>
                        </div>
                        <div class="column">
                            <b-switch v-model="trip.questions">Questions</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="trip.hotels">Hotels</b-switch>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!trip.title || !trip.location || !trip.start_on || !trip.end_on" @click="submit"><i class="fas fa-plus mr-3"></i>Duplicate Trip</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardBody from "@/TIER/components/CardBody";
    import TripForm from "@/cards/trips/TripForm";
    import CardFooter from "@/TIER/components/CardFooter";

    import {client as http} from '../../http_client';
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, SubheaderListItem, CardList, CardFooter, TripForm, CardBody, CardHeader},
        data: function() {
            return {
                trip: {
                    days: true,
                    addons: true,
                    events: true,
                    questions: true,
                    hotels: true
                }
            };
        },
        methods: {
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId, {force: true}).then(response => {
                    this.trip = {...this.trip, ...response.data};
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/duplicate', {
                    trip_id: this.trip.id,
                    title: this.trip.title,
                    location: this.trip.location,
                    start_on: this.trip.start_on,
                    end_on: this.trip.end_on,
                    days: this.trip.days,
                    addons: this.trip.addons,
                    events: this.trip.events,
                    questions: this.trip.questions,
                    hotels: this.trip.hotels
                }, {force: true}).then(response => {
                    this.$reloadCard('trips');
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>
